import { Route } from '../../../enums';

export const SiteSaversOrganism = () => {
  return (
    <section className="w-full bg-white mx-auto lg:max-w-[1800px] flex flex-col lg:flex-row items-center pb-20 lg:pb-0">
      <div className="w-full lg:w-1/2">
        <img
          className="hidden lg:block w-full border-box"
          src="/images/home/sightsavers-home.png"
          alt="waldo supports sightsavers charity"
        />
        <img
          className="block lg:hidden w-full border-box"
          src="/images/home/sightsavers-home-mobile.png"
          alt="waldo supports sightsavers charity"
        />
      </div>
      <div className="w-full lg:w-1/3 flex flex-col mx-auto">
        <div className="w-18/25 lg:w-3/4 mx-auto lg:mt-0">
          <h2 className="text-waldo-blue text-center lg:text-left text-header block w-full mx-auto mt-8 lg:mt-0 text-4xl lg:mx-0">
            Buy one, give vision
          </h2>
          <div className="flex flex-col items-center lg:items-start">
            <p className="text-lg lg:text-xl block w-9/12 lg:w-10/12 items-center inline-block text-center mt-4 lg:mt-2 md:text-left relative">
              We&apos;ve partnered with
              <img
                className="w-4/12 md:max-w-[105px] border-box text-center mx-2 mb-1 lg:mb-0 lg:mt-1 inline-block mb-0 pb-0 relative lg:absolute"
                src="/images/home/sightsavers-home-logo.svg"
                alt="sightsavers logo"
              />
              <br />
              to further pursue our mission of ending avoidable blindness and
              championing positive vision.
            </p>
          </div>
        </div>
        <div className="w-full lg:w-3/4 mx-auto max-w-400 mt-8 lg:mb-20 flex justify-center lg:justify-start flex-row">
          <a
            className="w-11/12 md:w-52 font-waldo-header text-base btn-simple-blue block mx-8 md:ml-0 py-2 md:py-4 text-center font-semibold"
            href={Route.SIGHT_SAVERS}
          >
            LEARN MORE
          </a>
        </div>
      </div>
    </section>
  );
};
